import React from "react";
import Avatar from "../components/Avatar";
import { NavLink } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";

export default function Sidebar() {
  const { user } = useUser();

  return (
    <>
      <div className="app-sidebar">
        <div className="app-sidebar-menu">
          <div className="app-sidebar-brand">
            {user?.profilePicture ? (
              <Avatar
                img={`${process.env.REACT_APP_BACKEND_URL}/image/${user?.profilePicture}`}
              />
            ) : (
              <Avatar text={user?.firstName} />
            )}
            <h5>
              <span>{user?.firstName + " " + user?.lastName}</span>
            </h5>
          </div>
          <ul>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`/`}
              >
                <span className="la-campground"></span>
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`/categories`}
              >
                <span className="las la-layer-group"></span>
                <span>Catégories</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={"items"}
              >
                <span className="las la-receipt"></span>
                <span>Services</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="customers"
              >
                <span className="las la-user"></span>
                <span>Clients</span>
              </NavLink>
            </li>
            <li>
              <a href="#">
                <span className="las la-users"></span>
                <span>Utilisateurs</span>
              </a>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={"setting"}
              >
                <span className="las la-cog"></span>
                <span>Paramètres</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="app-sidebar-footer"></div>
      </div>
    </>
  );
}
