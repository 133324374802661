import React, { useEffect, useState } from "react";
import { useApi } from "../../contexts/ApiProvider";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFlash } from "../../contexts/FlashProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ProductSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
});

export default function ItemEditPage() {
  const [product, setProduct] = useState();
  const [images, setImages] = useState([]);

  const itemId = useParams().itemId;
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();

  const handleImageChange = async (e) => {
    const newForm = new FormData();
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      newForm.append("images", file);
    });
    const response = await api.post(`/api/uploads/upload-imgs`, newForm);
    if (!response.error) {
      setImages((prev) => [...prev, ...response]);
    }
  };

  const removeImage = async (index) => {
    // assigning the list to temp variable
    const temp = [...images];
    // removing the element using splice
    temp.splice(index, 1);
    // updating the list
    setImages(temp);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: product?.title || "",
      subtitle: product?.subtitle || "",
      description: product?.description || "",
      content: product?.content || "",
      tags: product?.tags || "",
      url: product?.url || "",
      images: product?.images || [],
    },
    validationSchema: ProductSchema,
    onSubmit: async (values) => {
      values.images = images;
      if (product) {
        const response = await api.put(`/api/services/${itemId}`, values);
        if (!response.error) {
          flash(`Informations du service mise à jour`, "success");
          navigate(`/items`);
        } else {
          flash(`${response.error.message}`, "danger");
        }
      } else {
        const response = await api.post(`/api/services`, values);
        if (!response.error) {
          flash(`Nouveau service crée`, "success");
          navigate(`/items`);
        } else {
          flash(`${response.error.message}`, "danger");
        }
      }
    },
  });

  useEffect(() => {
    if (itemId) {
      (async () => {
        const response = await api.get(`/api/services/byid/${itemId}`);
        setProduct(response.service);
        setImages(response.service.images || []);
      })();
    }
  }, [itemId]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-form">
        <div className="app-form-header">
          <div className="container w-50 mx-auto">
            <h2>Nouveau service</h2>
          </div>
        </div>
        <div className="app-form-content">
          <div className="container w-50 mx-auto">
            <br />
            <div>
              <label className="pb-2">
                Titre <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="title"
                className="form-control"
                value={formik.values.title}
                onChange={formik.handleChange}
                placeholder="Entrer le titre..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">
                Sous Titre <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="subtitle"
                className="form-control"
                value={formik.values.subtitle}
                onChange={formik.handleChange}
                placeholder="Entrer le sous titre..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">
                Description <span className="text-danger">*</span>
              </label>
              <textarea
                cols="30"
                required
                rows="8"
                type="text"
                name="description"
                className="form-control"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Entrer la description..."
              ></textarea>
            </div>
            <br />
            <div>
              <label className="pb-2">
                Contenue <span className="text-danger">*</span>
              </label>
              <ReactQuill
                style={{ height: "300px", marginBottom: 50 }}
                name="content"
                cols="30"
                onChange={(e) => formik.setFieldValue("content", e)}
                value={formik.values.content || ""}
              />
              {formik.touched.content && formik.errors.content ? (
                <span className="text-red-500">{formik.errors.content}</span>
              ) : null}
            </div>
            <br />
            <div>
              <label className="pb-2">Tags</label>
              <input
                type="text"
                name="tags"
                value={formik.values.tags}
                className="form-control"
                onChange={formik.handleChange}
                placeholder="Enter your product tags..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">
                Site Web <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="url"
                className="form-control"
                value={formik.values.url}
                onChange={formik.handleChange}
                placeholder="Entrer le site web..."
              />
            </div>
            <br />
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="isArchived"
                checked={formik.values.isArchived}
                value={formik.values.isArchived}
                onChange={formik.handleChange}
                id="isArchived"
              />
              <label class="form-check-label" for="isArchived">
                is archived
              </label>
            </div>
            <br />
            <div>
              <label className="pb-2">
                Upload Images <span className="text-danger">*</span>
              </label>
              <input
                type="file"
                name="images"
                id="images"
                className="hidden"
                style={{ display: "none" }}
                multiple
                onChange={handleImageChange}
              />
              <div className="w-full flex items-center flex-wrap">
                <label htmlFor="images">
                  <span className="las la-plus-circle fs-1 mt-3"></span>
                </label>
                {images &&
                  images.map((image, index) => (
                    <>
                      <img
                        src={`${process.env.REACT_APP_BACKEND_URL}/image/${image.name}`}
                        key={index}
                        alt=""
                        className="h-[120px] w-[120px] object-cover m-2"
                        style={{ width: "120px", height: "120px" }}
                      />
                      <span
                        onClick={() => removeImage(index)}
                        className="bi bi-dash-circle fs-5 posistion-absolute top-1"
                      ></span>
                    </>
                  ))}
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="app-form-footer">
          <div className="container w-50 mx-auto">
            <div className="d-flex align-items-center justify-content-between">
              <Link to={`/items`} className="btn btn-main-outline">
                ANNULER
              </Link>
              <button type="submit" className="btn btn-main">
                ENREGISTRER
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
