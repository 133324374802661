import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AppLayout from "./layouts/AppLayout";
import LoginPage from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";
import DashboardPage from "./pages/main/DashboardPage";
import ProfilePage from "./pages/main/ProfilePage";
import ProfileEditPage from "./pages/main/ProfileEditPage";
import ItemLayout from "./pages/items/ItemLayout";
import ItemListPage from "./pages/items/ItemListPage";
import ItemEditPage from "./pages/items/ItemEditPage";
import ItemDetailsPage from "./pages/items/ItemDetailsPage";
import UserLayout from "./pages/users/UserLayout";
import UserEditPage from "./pages/users/UserEditPage";
import UserDetailsPage from "./pages/users/UserDetailsPage";
import UserListPage from "./pages/users/UserListPage";
import SettingPage from "./pages/main/SettingPage";
import NotificationPage from "./pages/main/NotificationPage";

import CategoryLayout from "./pages/categories/CategoryLayout";
import CategoryListPage from "./pages/categories/CategoryListPage";
import CategoryEditPage from "./pages/categories/CategoryEditPage";
import CustomerLayout from "./pages/customers/CustomerLayout";
import CustomerListPage from "./pages/customers/CustomerListPage";
import CustomerEditPage from "./pages/customers/CustomerEditPage";
import CustomerDetailsPage from "./pages/customers/CustomerDetailsPage";

export default function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />}></Route>
      <Route path="/register" element={<RegisterPage />}></Route>
      <Route
        path={`/`}
        element={
          <RequireAuth>
            <AppLayout />
          </RequireAuth>
        }
      >
        <Route path="" element={<DashboardPage />} />
        <Route path="settings" element={<SettingPage />} />
        <Route path="notifications" element={<NotificationPage />} />
        <Route path="profile" element={<ProfilePage />}>
          <Route path="edit" element={<ProfileEditPage />}></Route>
        </Route>
        <Route path="categories" element={<CategoryLayout />}>
          <Route path="" element={<CategoryListPage />}></Route>
          <Route path="add" element={<CategoryEditPage />}></Route>
          <Route path=":categoryId" element={<ItemDetailsPage />}></Route>
          <Route path=":categoryId/edit" element={<CategoryEditPage />}></Route>
        </Route>
        <Route path="customers" element={<CustomerLayout />}>
          <Route path="" element={<CustomerListPage />}></Route>
          <Route path="add" element={<CustomerEditPage />}></Route>
          <Route path=":customerId" element={<CustomerDetailsPage />}></Route>
          <Route path=":customerId/edit" element={<CustomerEditPage />}></Route>
        </Route>
        <Route path="items" element={<ItemLayout />}>
          <Route path="" element={<ItemListPage />}></Route>
          <Route path="add" element={<ItemEditPage />}></Route>
          <Route path=":itemId" element={<ItemDetailsPage />}></Route>
          <Route path=":itemId/edit" element={<ItemEditPage />}></Route>
        </Route>
        <Route path="users" element={<UserLayout />}>
          <Route path="" element={<UserListPage />}></Route>
          <Route path="add" element={<UserEditPage />}></Route>
          <Route path=":id" element={<UserDetailsPage />}></Route>
          <Route path=":id/edit" element={<UserEditPage />}></Route>
        </Route>
      </Route>
    </Routes>
  );
}

function RequireAuth({ children }) {
  let auth = localStorage.getItem("user");
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
